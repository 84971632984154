<template>
  <v-row class="messages">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Messages History</div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <div class="accent py-2 px-sm-10 px-2 custom">
          <v-card width="100%" color="accent" flat class="d-md-flex align-md-end">
            <v-select
              v-model="date"
              :items="periods"
              item-text="title"
              item-value="name"
              dense
              outlined
              hide-details
              color="primary"
              height="40"
              @change="getData"
              class="mr-5 my-sm-0 my-1"
            ></v-select>
            <span v-if="date == 'custom'">
              <v-menu v-model="modalFrom" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-5 my-sm-0 my-1"
                    v-model="dateFrom"
                    placeholder="From"
                    hide-details
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    required
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    height="40"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="dateTo"
                  color="primary"
                  v-model="dateFrom"
                  @input="modalFrom = false"
                  @change="getData"
                ></v-date-picker>
              </v-menu>
              <v-menu v-model="modalTo" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-5 my-sm-0 my-1"
                    v-model="dateTo"
                    placeholder="To"
                    hide-details
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    required
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    height="40"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="dateFrom"
                  :max="new Date().toISOString().substr(0, 10)"
                  color="primary"
                  v-model="dateTo"
                  @input="modalTo = false"
                  @change="getData"
                ></v-date-picker>
              </v-menu>
            </span>
            <v-btn v-if="date == 'custom' || !data.total" text color="gray" @click="clear">
              Clear <v-icon>mdi-close mdi-18px</v-icon>
            </v-btn>
          </v-card>
        </div>
        <div>
          <v-card flat height="70vh" min-height="260" v-if="nothing" class="pa-3 d-flex align-center justify-center flex-column">
            <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
            <div class="text-center text-h5 font-weight-bold mt-8">Sorry, but nothing found</div>
          </v-card>
          <v-card
            flat
            height="70vh"
            min-height="260"
            v-else-if="block || !data.result.length"
            class="pa-3 d-flex align-center justify-center flex-column"
          >
            <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
            <div class="text-center text-h6 font-weight-bold mt-8">Looks like there are no messages for your account.</div>
            <!-- <div class="text-center opasity--text font-weight-regular mt-2">
              Please, contact support to resolve this issue.
            </div> -->
          </v-card>
          <div v-else>
            <v-data-table
              hide-default-footer
              fixed-header
              :headers="headers"
              :items="data.result"
              :items-per-page="Number(itemsPerPage)"
              :height="'calc(100vh - 287px)'"
              class="d-sm-block d-none p-table"
            >
              <template v-slot:header.account>
                Account
                <v-menu bottom :close-on-content-click="false" transition="slide-y-transition" v-model="modal">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="account == 'all' ? 'opasity--text white' : 'white--text input'"
                      class="pa-0"
                      height="24"
                      min-width="24"
                      x-small
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-filter mdi-18px</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-2">
                    <v-autocomplete
                      v-model="account"
                      :items="accountsList"
                      dense
                      id="acc"
                      outlined
                      color="primary"
                      item-text="name"
                      item-value="id"
                      hide-details
                      @change="getData(), (modal = false)"
                    ></v-autocomplete>
                  </v-card>
                </v-menu>
              </template>
              <template v-slot:header.status>
                Status
                <v-menu bottom :close-on-content-click="true" transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="!selectedItem ? 'opasity--text white' : 'white--text input'"
                      class="pa-0"
                      height="24"
                      min-width="24"
                      x-small
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-filter mdi-18px</v-icon>
                    </v-btn>
                  </template>
                  <v-list-item-group v-model="selectedItem" color="primary">
                    <v-list>
                      <v-list-item v-for="(item, i) in msgStatus" :key="item.name" @click="valueSelect(item.name, i)">
                        <v-list-item-title class="d-flex">
                          <div :class="item.name != 'all' ? getColorClass(item.title) + ' circle' : ''"></div>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-list-item-group>
                </v-menu>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id" height="52" class="link" @click="openItem(item.id)">
                    <td>{{ new Date(item.date).toLocaleString() }}</td>
                    <td class="text-capitalize">{{ item.account.name }}</td>
                    <td>{{ item.to }}</td>
                    <td>{{ item.origin }}</td>
                    <td>{{ item.cost }}</td>
                    <td>
                      <div class="ml-auto status" :class="getColorClass(item.status)">{{ item.status }}</div>
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-slot:footer>
                <v-divider></v-divider>
                <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                  <span class="grey--text">Rows per page:</span>
                  <div class="perPageSelect">
                    <v-select
                      v-model="itemsPerPage"
                      :items="itemsPerPageArray"
                      menu-props="auto"
                      hide-details
                      single-line
                      dense
                      @change="getData"
                    ></v-select>
                  </div>
                  <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                  <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </template>
            </v-data-table>
            <div class="d-block d-sm-none">
              <div v-for="item in data.result" :key="item.id" class="px-4 pt-2">
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Date</div>
                  <div class="font-weight-medium f18">{{ new Date(item.date).toLocaleString() }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Account</div>
                  <div class="font-weight-medium f18 text-capitalize">{{ item.account.name }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">To</div>
                  <div class="font-weight-medium f18">{{ item.to }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Origin</div>
                  <div class="font-weight-medium f18 text-capitalize">{{ item.origin }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Cost</div>
                  <div class="font-weight-medium f18">{{ item.cost }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Status</div>
                  <div class="font-weight-medium f18 text-capitalize">{{ item.status }}</div>
                </div>
                <v-divider></v-divider>
              </div>
              <v-row class="my-2 pb-2" align="center" justify="center">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <div>
                  <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="mx-3 mt-1 grey--text">{{ page }} of {{ data.pages }}</span>
                  <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      modal: false,
      itemsPerPage: +this.$route.query.page_size || 10,
      page: +this.$route.query.page || 1,
      status: this.$route.query.status || 'all',
      account: this.$route.query.account || 'all',
      date: this.$route.query.date || 'last_7_days',
      dateFrom: this.$route.query.date_from || new Date().toISOString().substr(0, 10),
      dateTo: this.$route.query.date_to || new Date().toISOString().substr(0, 10),
      modalFrom: false,
      modalTo: false,
      selectedItem: 0,
      block: true,
      nothing: false,
      headers: [
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Account', value: 'account', sortable: false },
        { text: 'To', value: 'to', sortable: false },
        { text: 'Origin', value: 'origin', sortable: false },
        { text: 'Cost', value: 'cost', sortable: false },
        { text: 'Status', align: 'end', value: 'status', sortable: false },
      ],
      statusError: null,
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getPagination');
    this.$store.dispatch('getPeriods');
    this.$store.dispatch('getMsgStatus');
    this.$store.dispatch('getAccountsAll').then(() => {
      this.accountsList.unshift({ id: 'all', name: 'All' });
    });
  },
  methods: {
    getColorClass(block) {
      if (block == 'Scheduled') {
        return 'warning white--text';
      } else if (block == 'Sent') {
        return 'success white--text';
      } else if (block == 'Delivered') {
        return 'input white--text';
      } else if (block == 'Filtered') {
        return 'red darken-1 white--text';
      } else if (block == 'Send failed') {
        return 'red darken-1 white--text';
      } else if (block == 'Low balance' || block == 'Payment failed') {
        return 'gray white--text';
      } else if (block == 'Undelivered') {
        return 'white opasity--text';
      }
    },
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({ message: 'Error', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clear() {
      this.selectedItem = 0;
      this.status = 'all';
      this.account = 'all';
      this.date = 'last_7_days';
      this.dateFrom = new Date().toISOString().substr(0, 10);
      this.dateTo = new Date().toISOString().substr(0, 10);
      this.getData();
    },
    valueSelect(status, i) {
      this.selectedItem = i;
      this.status = status;
      this.getData();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    openItem(id) {
      this.$router.push({ name: 'Message', params: { msgId: id } });
    },
    async getData() {
      await this.$store
        .dispatch('getMessagesList', {
          pageSize: this.itemsPerPage,
          status: this.status,
          account: this.account,
          date: this.date,
          page: this.page,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        })
        .then(() => {
          this.nothing = false;
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setMessagesList', {});
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setMessagesList', {});
        this.nothing = true;
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  watch: {
    modal(val) {
      if (val) {
        setTimeout(() => {
          document.getElementById('acc').click();
        }, 200);
      }
    },
    msgStatus() {
      this.msgStatus.find((item, i) => {
        item.name == this.$route.query.status ? (this.selectedItem = i) : 0;
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.messagesList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    periods() {
      return this.$store.getters.periods;
    },
    msgStatus() {
      return this.$store.getters.msgStatus;
    },
    accountsList() {
      return this.$store.getters.accountsAll;
    },
  },
  destroyed() {
    this.$store.dispatch('setMessagesList', {});
    this.$store.dispatch('setAccountsAll', []);
  },
};
</script>

<style lang="scss">
.messages {
  .custom {
    .v-input {
      display: inline-block;
      max-width: 160px;
      width: 100%;
      .v-input__append-inner {
        margin-top: 8px !important;
      }
    }
  }
  .status {
    line-height: 24px;
    padding: 0 16px;
    width: max-content;
    border-radius: 24px;
  }
}
#app .circle {
  margin-right: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid;
  border-color: var(--v-gray-base) !important;
}
</style>
